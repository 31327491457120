@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";

@import "~prismjs/themes/prism-tomorrow.css";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/plugins/diff-highlight/prism-diff-highlight.css";
@import "~prismjs/plugins/line-highlight/prism-line-highlight.css";
@import "~prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "~prismjs/plugins/match-braces/prism-match-braces.css";


:root,
[data-bs-theme=dark] {
	--box-primary: #00FF78;
	--box-secondary: #00DBFF;
	--box-highlights: #FFF500;
	--box-danger: #E34E46;
	--box-bgBoxes: #080D17;
	--box-body-bg: #0E0F1A;
	--box-bgBox-75: #0E141E;
	--box-bgBox-50: #131C2A;
	--box-navbar-bg: #050C16;
	--box-primary-rgb: 0, 255, 120;
	--box-primary-rgba-10: rgba(0, 255, 120, 0.1);
	--box-primary-rgba-30: rgba(0, 255, 120, 0.3);
	--box-secondary-rgba: rgba(0, 219, 255, 0.3);
	--box-secondary-rgb: 0, 219, 255;
	--box-secondary-rgba-20: rgba(0, 219, 255, 0.2);
	--box-body-bg-rgba: rgba(14, 15, 26, 0);
	--box-body-bg-rgb:14, 15, 26;
	--box-gradient-vertical: linear-gradient(to top, var(--box-secondary), var(--box-primary));
	--box-gradient-diagonal: linear-gradient(to right top, var(--box-secondary), var(--box-primary));
	--box-light: #FFFFFF;
	--bs-nav-link-color: #fff;
	--box-dropdown-item-hover-bg: #3DDCFF;
	--box-border-radius: 5px;
}

@include color-mode(dark) {

	input {
		background-color: var(--box-input-bg);
		border-color: var(--box-input-bg);
	}

	h2,
	a,
	.title-color {
		color: var(--box-primary);
	}

	.nav-link {
		color: #fff !important;
	}
}

@include color-mode(light) {}